.landing-alert{
    margin-bottom: 0;
}
ul.description {
    font-size: 24px;
    font-weight: 400;
    list-style-image: url("~assets/img/lof/arrow.png");

    li {
        margin-top: 20px;
    }
}
.section.become-a-lord {
    background: url("~assets/img/lof/background_default.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media only screen and (max-width: 400px) {
        background: url("~assets/img/lof/background_cel.jpg");
    }
}
.scroll {
    background: url("~assets/img/lof/scroll.png");
    height: 280px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    .subtitle {
        margin-top: 90px;
        font-size: 30px;
        color: #561400;
        @media only screen and (max-width: 400px) {
            font-size: 24px;
        }
    }
}
.king .scroll {
    @media only screen and (max-width: 400px) {
        height: 480px;
    }
}
.king .scroll h2.subtitle {
    margin-top: 10px;
    font-size: 32px;

    @media only screen and (max-width: 400px) {
        margin-top: 50px;
    }
}
.king .scroll h3.subtitle {
    margin-top: 0px;
    font-size: 22px;
}

.parliament .title {
    font-size: 60px;
    font-weight: 400;
    font-family: 'Pirata One', cursive;
}
.subheader {
    color: $dark-gray;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
  }
.currencies .card-title {
    font-size: 36px;
}

.section .card-middle {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 340px;
}
.section.main-characters .card-middle {
    margin-top: -80px;
    z-index: 3;
    @media only screen and (max-width: 400px) {
        margin-top: 80px;
    }
}
.section.become-a-lord {
    padding-top: 0px;
}
.castle-status {
    @media only screen and (max-width: 400px) {
        padding: 30px;
    }
}
.page-header {
    background-color: #FFFFFF;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 1155px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            z-index: 1;
        }
    }
}

.page-header .motto {
    text-align: left;
    z-index: 3;
    color: $font-color;
    position: relative;
    padding-top: 400px;
    padding-bottom: 300px;
    font-weight: $font-weight-normal;

    @media (max-width: 768px) {
        padding-top: 150px;
    }

    p {
        font-size: 20px;
        @media only screen and (max-width: 400px) {
            padding: 10px;
        }
    }
}

.table {
    margin-top: 1rem;
    padding: 1rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    .row {
        margin: 1rem;
    }
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6 {
        justify-content: center;
        display: flex;
        align-content: center;
        margin-top: 0.5rem;
    }
    .input-group {
        width: auto;
    }
    label {
        margin-top: 0.5rem;
    }
    .btn, .btn-morphing {
        @media (max-width: 768px) {
            margin-top: 10px;
        }
    }
}
.table-header {
    background: rgba(233, 218, 180, 0.25);
}
.justify-start {
    display: flex;
    justify-content: start;
}
.justify-center {
    display: flex;
    justify-content: center;
}
.section.castle {
    padding-top: 0;
}
.castle .subtitle {
    font-size: 30px;
}
.kingdom-status {
    font-size: 16px;
    line-height: 40px;
    margin-left: 10px;
}
.merchants .page-header, .parliament .page-header {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.lords .page-header {
    background-position: 20% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}
.merchants .page-header {
    background-image: url("~assets/img/lof/merchants_hero.jpg");

    @media only screen and (max-width: 400px) {
        background-image: url("~assets/img/lof/merchants_cel.jpg");
    }
}
.landing .page-header, .king .page-header {
    background-image: url("~assets/img/lof/index_hero.jpg");

    @media only screen and (max-width: 400px) {
        background-image: url("~assets/img/lof/home_cel.jpg");
    }
}

.king-header {
    background-position: 25% 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~assets/img/lof/king_hero_part.png");

    @media only screen and (max-width: 400px) {
        background-position: center 300px;
    }
}


.lords .page-header, .merchants .page-header, .king .page-header {
    height: 691px;
    min-height: 691px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            z-index: 1;
        }
    }
}
.king h1 {
    color: #F8DFA8;
    text-shadow: 0px 0px 20px #902100;
    font-size: 90px;
}
.king .filter {
    background: rgba(255, 255, 255, 0.85);
    filter: blur(100px);
    position: relative;
    height: 691px;
}
.king .motto {
    margin-top: -750px;

    @media only screen and (max-width: 400px) {
        margin-top: -570px;
    }
}

.merchants .motto {
    @media only screen and (min-width: 400px) {
        margin-left: 70%;
    }
}
.lords .motto {
    @media only screen and (min-width: 400px) {
        margin-left: 60%;
    }
    @media only screen and (max-width: 400px) {
        margin-top: 150px;
    }
}
.merchants, .lords {
    h2, .h2, h3, .h3 {
        color: #902100
    }
    .motto {
        padding-top: 300px;

        @media only screen and (max-width: 400px) {
            margin-left: 10%;
        }
        .wallet-balance {
            width: 300px;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
            padding: 20px;
            margin-top: 10px;

            .input-group {
                font-size: 20px;
                font-weight: 600;
                color: #902100;
                justify-content: center;
            }
        }
    }
    .buy-sell-form {
        margin-top: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 40px;

        .form-control {
            height: auto;
        }
    }
}
.dropdown .btn.dropdown-toggle {
    font-weight: 500;
    color: #902100;
    background: #F4EAD3;
    border: 1px solid #902100;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 10px;
    min-width: 92px;
    outline: 0;
}
.landing-section{
    padding: 100px 0;
    z-index: 1;
}
.section-buttons{
    z-index: 1;
}
.landing-section .btn-simple{
    padding: 0;
}
.landing-section .column{
    padding: 0 75px 0 25px;
}
.team-player .img-circle, .team-player .img-thumbnail{
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}
.contact-form{
    margin-top: 30px;
}
.contact-form label{
    margin-top: 15px;
}
.contact-form .btn{
    margin-top: 30px;
}

.navbar-relative{
    position: relative;
    z-index: 2;
}
#register-navbar a{
    color: #FFF;
}
.register-background .container{
    margin-top: 11%;
    position: relative;
    z-index: 3;
}
.register-footer{
    bottom: 20px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: $white-color;
}
.register-footer .fa-heart{
    color: #EB5E28;
}

.register-card label{
    margin-top: 15px;
}
.register-card .title{
    color: #B33C12;
    text-align: center;
}
.register-card .btn{
    margin-top: 30px;
}
.register-card .forgot{
    text-align: center;
}

.profile-content{
    position: relative;
}
.owner{
    text-align: center;
}
.owner .avatar{
    padding: 15px;
    max-width: 180px;
    margin: -85px auto 0;
    display: inline-block;
}
.owner .name h4{
    margin-top: 10px;
}
.profile-tabs{
    margin: 50px 0;
    min-height: 300px;
}
#following h3{
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox{
    margin-top: -15px;
}
#follows .follows h6{
    margin-top: 15px;
}
.follows hr{
    margin-top: 10px;
}
.alert h5{
    // margin-bottom: 10px;
}
.title-brand{
    max-width: 730px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    color: #FFFFFF;
    display: block;

    .type{
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 600;
        margin-top: 10px;
        right: -15px;
    }
}

.presentation-title{
    font-size: 8em;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    background: #fbf8ec;
    background: -moz-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 35%,#4e6773 100%);
    background: -ms-linear-gradient(top, #FFFFFF 35%,#4e6773 100%);
    background: linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.presentation-subtitle{
    font-size: 1.7em;
    color: #FFFFFF;
}

.category-absolute{
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);

    .creative-tim-logo {
        max-width: 140px;
        top: -2px;
        left: 3px;
        position: relative;
    }
}

.fog-low{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -35%;
    margin-bottom: -50px;
    width: 110%;
    opacity: .85;

    img{
        width: 100%;
    }

    &.right{
        margin-left: 30%;
        opacity: 1;
    }
}
.page-header{
    .content-center{
        margin-top: 85px;
    }
}
.moving-clouds{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    -webkit-animation: cloudLoop 80s linear infinite;
    animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop{   0%{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
                        100%{-webkit-transform:translate3d(-50%,0,0);
                        transform:translate3d(-50%,0,0)}
                    }
.following{
    img{
        max-width: 70px;
    }
}


.profile-content{
    & .tab-content{
        & .tab-pane{
            min-height: 200px;
        }
        & #tweets, #connections, #media{
            height: 100%;
        }
    }
}
